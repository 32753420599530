import type { RouterConfig } from '@nuxt/schema';

import { authRouter } from '@/app-modules/auth/router';
import { incidentRoutes } from '@/app-modules/incident/router';
import { productRoutes } from '@/app-modules/product/router';
import { actionRoutes } from '@/app-modules/action/router';
import { configurationRoutes } from '@/app-modules/configuration/router';
import { profileRoutes } from '@/app-modules/profile/router';
import { componentLibraryRoutes } from '@/app-modules/component-library/router';
import { exploreRoutes } from '~/app-modules/explore/router';
import { standardRoutes } from '~/app-modules/standard/router';
import { exploreGlobalRoutes } from '~/app-modules/explore-global/router';
import { overviewRoutes } from '~/app-modules/overview/router';
import { newDashboardRoutes } from '~/app-modules/new-dashboard/router';
import { maintenanceRoutes } from '~/app-modules/maintenance/router';
import { adminRoutes } from '~/app-modules/admin/router';

export default {
  routes: (_routes) => [
    ...adminRoutes,
    ...authRouter,
    ...incidentRoutes,
    ...productRoutes,
    ...actionRoutes,
    ...exploreRoutes,
    ...exploreGlobalRoutes,
    ...standardRoutes,
    ...configurationRoutes,
    ...profileRoutes,
    ...componentLibraryRoutes,
    ...newDashboardRoutes,
    ...overviewRoutes,
    ...maintenanceRoutes,
  ],
} satisfies RouterConfig;
