import { signInWithEmailAndPassword, SAMLAuthProvider, signInWithPopup, type UserCredential } from 'firebase/auth';
import { useI18n } from 'vue-i18n';
import * as Sentry from '@sentry/nuxt';
import dayjs from 'dayjs';

import fr from 'dayjs/locale/fr';
dayjs.locale(fr);

interface State {
  firebaseUser: null | UserCredential['user'];
  unsubscribe: null | (() => void);
  i18n: ReturnType<typeof useI18n>;
  accessToken: null | string;
}

export const firebaseStore = defineStore({
  id: 'firebaseStore',
  state: (): State => ({
    firebaseUser: null,
    unsubscribe: null,
    i18n: useI18n(),
    accessToken: null,
  }),
  getters: {
    getFirebaseUser(): UserCredential['user'] | null {
      return this.firebaseUser;
    },
    getAccessToken(): string | null {
      return this.accessToken;
    },
  },
  actions: {
    async initAuth() {
      const { $firebase } = useNuxtApp();
      this.firebaseUser = $firebase.currentUser;
      this.accessToken = await $firebase.currentUser?.getIdToken();
    },
    async signIn(form: { email: string; password: string }) {
      const { $firebase } = useNuxtApp();
      const { initApp } = globalStore();
      const { $posthog } = useNuxtApp();
      const router = useRouter();

      try {
        const data = await signInWithEmailAndPassword($firebase, form.email, form.password);
        this.firebaseUser = data.user;
        this.accessToken = await $firebase.currentUser?.getIdToken();

        await $firebase.authStateReady();
        await initApp();

        const user = userStore().getCurrentUser;

        Sentry.setUser(user);
        $posthog.identify(user.id, {
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          client_id: user.client.name,
        });
        $posthog.group('client', user.client.id.toString(), {
          client_id: user.client.id,
          name: user.client.name,
        });
        $posthog.capture('user_signed_in');
        $posthog.capture('hour_of_sign_in', {
          hour: dayjs().hour(),
        });
        $posthog.capture('day_of_sign_in', {
          day: dayjs().locale('fr').format('dddd'),
        });

        router.push('/');
      } catch (error) {
        if (error.code === 'auth/user-not-found') {
          toastStore().showToast({
            type: 'error',
            title: this.i18n.t('global.error'),
            message: this.i18n.t('global.error_login_invalid_user'),
          });
        } else if (error.code === 'auth/invalid-credential') {
          toastStore().showToast({
            type: 'error',
            title: this.i18n.t('global.error'),
            message: this.i18n.t('global.error_login_invalid_password'),
          });
        } else {
          toastStore().showToast({
            type: 'error',
            title: this.i18n.t('global.error'),
            message: this.i18n.t('global.error_login_unknown'),
          });
        }
      }
    },
    async signInWithSSO(saml: string) {
      const { $firebase } = useNuxtApp();
      const { initApp } = globalStore();
      const router = useRouter();
      const { $posthog } = useNuxtApp();
      try {
        const provider = new SAMLAuthProvider(saml);
        const data = await signInWithPopup($firebase, provider);
        this.firebaseUser = data.user;

        await initApp();

        const user = userStore().getCurrentUser;
        Sentry.setUser(user);
        $posthog.identify(user.id, {
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          client_id: user.client.name,
        });
        $posthog.group('client', user.client.id.toString(), {
          client_id: user.client.id,
          name: user.client.name,
        });
        $posthog.capture('user_signed_in');
        $posthog.capture('hour_of_sign_in', {
          hour: dayjs().hour(),
        });
        $posthog.capture('day_of_sign_in', {
          day: dayjs().locale('fr').format('dddd'),
        });

        router.push('/');
      } catch (error) {
        toastStore().showToast({
          type: 'error',
          title: this.i18n.t('global.error'),
          message: this.i18n.t('global.error_login_unknown'),
        });
        throw error;
      }
    },
    async logout() {
      const { $firebase } = useNuxtApp();
      const { $posthog } = useNuxtApp();

      await $firebase.signOut();
      Sentry.setUser(null);
      $posthog.reset();
    },
  },
});
