<script lang="ts" setup>
// Stores
const global = globalStore();
const firebase = firebaseStore();
const { $firebase } = useNuxtApp();

// Init App Function
const init = async () => {
  const { $config } = useNuxtApp();
  if ($config.public.maintenance) {
    global.setLoading(false);
    return;
  }
  await $firebase.authStateReady();
  await firebase.initAuth();
  global.initApp();
};
init();
</script>
<template>
  <tooltip-provider :delay-duration="200">
    <nuxt-layout v-if="!global.loading">
      <nuxt-page />
    </nuxt-layout>
    <div v-else class="flex items-center justify-center flex-col h-screen w-screen">
      <img src="./assets/nrjx-logo.svg" class="h-16 mb-8" />
      <ui-loader />
    </div>

    <!-- Modal and toast -->
    <ui-toast />
    <ui-alert />
  </tooltip-provider>
</template>
