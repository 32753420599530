import type { RouteRecordRaw } from 'vue-router';

export const newDashboardRoutes: RouteRecordRaw[] = [
  {
    name: 'new-dashboard-index',
    component: () => import('../pages/index.vue'),
    path: '/new-dashboard',
    meta: {
      middleware: 'auth',
    },
  },
];
