import type { RouteRecordRaw } from 'vue-router';

export const adminRoutes: RouteRecordRaw[] = [
  {
    name: 'admin-index',
    path: '/admin',
    component: () => import('../pages/index.vue').then((r) => r.default || r),
    meta: {
      middleware: 'auth',
    },
  },
  {
    name: 'admin-clients-index',
    path: '/admin/clients',
    component: () => import('../pages/client/index.vue').then((r) => r.default || r),
    meta: {
      middleware: 'auth',
    },
  },
  {
    name: 'admin-clients-view',
    path: '/admin/clients/:id',
    component: () => import('../pages/client/view.vue').then((r) => r.default || r),
    meta: {
      middleware: 'auth',
    },
  },
  {
    name: 'admin-clients-site-view',
    path: '/admin/site/:id/',
    component: () => import('../pages/site/view.vue').then((r) => r.default || r),
    meta: {
      middleware: 'auth',
    },
    children: [
      {
        name: 'admin-site-view-information',
        path: '',
        component: () => import('../pages/site/tabs/information.vue').then((r) => r.default || r),
        meta: {
          middleware: 'auth',
        },
      },
      {
        name: 'admin-site-view-areas',
        path: 'areas',
        component: () => import('../pages/site/tabs/area.vue').then((r) => r.default || r),
        meta: {
          middleware: 'auth',
        },
      },
    ],
  },
];
