import revive_payload_client_HNzU9GEfR551_KuR1_6Vlit7FqTEVTbc2SVETiO_Axs from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_detgp5hi6dobrpg6yximild2uu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_MSlVzVg3dyKTjZIGzfKxyC_PhskLFL_3liljO0kBjaQ from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_detgp5hi6dobrpg6yximild2uu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router____2Cu_y82G65_Jdw98WWkLxUlIHfCOaxYYtvZ0iNkI from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_detgp5hi6dobrpg6yximild2uu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ynPkaE39ilyriCWTmKhocxBRRnxsFyJd250r3uRSC3E from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_detgp5hi6dobrpg6yximild2uu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3YGvh_WAHWEyFSLMSSBRf_L4xREPYyEjRZR_QYDr4tI from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_detgp5hi6dobrpg6yximild2uu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vugxg5_oBahsTsY4sPAOn3IYu1K7tjPclCpTkVjPi_M from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_detgp5hi6dobrpg6yximild2uu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0eUYGTiaEH_dtlbZLNU5quiLConUwD5Rpg5ikp_fEXI from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_detgp5hi6dobrpg6yximild2uu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/home/runner/work/nrjx/nrjx/apps/webapp/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_fvuh1GmJT5_CZuATy116S1zNmNjzSclWF7RDsF6po9U from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_detgp5hi6dobrpg6yximild2uu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KXA6sPMHjBaGJz8pzXm8V5BUnQNp_Cq1zNfsPjWVR4I from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_jxYNVJEpH0y32JKHMVTWZgyTHFzZPl96z6gy48xbvuQ from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_rollup@4_gaj2iz5uhrpgepgndx2hgmfi4u/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_U7VTl2NSw9YUgK3Vy5oFktCpXA70yh5PpuEgDFX1l54 from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_rollup@4_gaj2iz5uhrpgepgndx2hgmfi4u/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_R24Mw11pi8E8aJkNLtUsbMuN4BU_NNWlOBGNMm2OJbU from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_rollup@4_gaj2iz5uhrpgepgndx2hgmfi4u/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_client_8m9A1E6BxM8BFyX8YnkmIDG7Vw55BRnIuJ4NjQSdycw from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/@sentry+nuxt@8.55.0_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1_@opent_yweh5bz7clgjwjy5mchc2suhgm/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_wjhrcCL76ies_5saPwWOCD7XHMK0wr7fih8C3HPfjCo from "/home/runner/work/nrjx/nrjx/apps/webapp/node_modules/.cache/nuxt/.nuxt/sentry-client-config.mjs";
import crips_kGUePBSY6kftjpUwEzg2_WmcszyHRQWH3P_pwp5oK4k from "/home/runner/work/nrjx/nrjx/apps/webapp/app/plugins/crips.ts";
import firebase_naranse2NcGm1zUNyHsHfIH6zwFPb7aXyMxNwEUzFWw from "/home/runner/work/nrjx/nrjx/apps/webapp/app/plugins/firebase.ts";
import posthog_L2WhwPMYsQFde4_Sg_Fg_CY7KuXhtI4gfay4nevR2z0 from "/home/runner/work/nrjx/nrjx/apps/webapp/app/plugins/posthog.ts";
import veeValidate_MFBrkfXMeVlkayYQsYUZtFucT8NVkCj4GVytr_i8ksE from "/home/runner/work/nrjx/nrjx/apps/webapp/app/plugins/veeValidate.ts";
import yup_0rKjvylj0PF8ijAgU_Xoycg_oE5KYgXRcxffKsP4xts from "/home/runner/work/nrjx/nrjx/apps/webapp/app/plugins/yup.ts";
import ssg_detect_7klEwwoohFuvSlnXAu8BeQH_AhKzZ3Vy_YNmlgOnSfs from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_rollup@4_gaj2iz5uhrpgepgndx2hgmfi4u/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_HNzU9GEfR551_KuR1_6Vlit7FqTEVTbc2SVETiO_Axs,
  unhead_MSlVzVg3dyKTjZIGzfKxyC_PhskLFL_3liljO0kBjaQ,
  router____2Cu_y82G65_Jdw98WWkLxUlIHfCOaxYYtvZ0iNkI,
  payload_client_ynPkaE39ilyriCWTmKhocxBRRnxsFyJd250r3uRSC3E,
  navigation_repaint_client_3YGvh_WAHWEyFSLMSSBRf_L4xREPYyEjRZR_QYDr4tI,
  check_outdated_build_client_vugxg5_oBahsTsY4sPAOn3IYu1K7tjPclCpTkVjPi_M,
  chunk_reload_client_0eUYGTiaEH_dtlbZLNU5quiLConUwD5Rpg5ikp_fEXI,
  plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_fvuh1GmJT5_CZuATy116S1zNmNjzSclWF7RDsF6po9U,
  plugin_KXA6sPMHjBaGJz8pzXm8V5BUnQNp_Cq1zNfsPjWVR4I,
  switch_locale_path_ssr_jxYNVJEpH0y32JKHMVTWZgyTHFzZPl96z6gy48xbvuQ,
  route_locale_detect_U7VTl2NSw9YUgK3Vy5oFktCpXA70yh5PpuEgDFX1l54,
  i18n_R24Mw11pi8E8aJkNLtUsbMuN4BU_NNWlOBGNMm2OJbU,
  sentry_client_8m9A1E6BxM8BFyX8YnkmIDG7Vw55BRnIuJ4NjQSdycw,
  sentry_client_config_wjhrcCL76ies_5saPwWOCD7XHMK0wr7fih8C3HPfjCo,
  crips_kGUePBSY6kftjpUwEzg2_WmcszyHRQWH3P_pwp5oK4k,
  firebase_naranse2NcGm1zUNyHsHfIH6zwFPb7aXyMxNwEUzFWw,
  posthog_L2WhwPMYsQFde4_Sg_Fg_CY7KuXhtI4gfay4nevR2z0,
  veeValidate_MFBrkfXMeVlkayYQsYUZtFucT8NVkCj4GVytr_i8ksE,
  yup_0rKjvylj0PF8ijAgU_Xoycg_oE5KYgXRcxffKsP4xts,
  ssg_detect_7klEwwoohFuvSlnXAu8BeQH_AhKzZ3Vy_YNmlgOnSfs
]