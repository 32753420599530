import { Crisp } from 'crisp-sdk-web';

export default defineNuxtPlugin(() => {
  Crisp.configure('6eb8f29f-9750-4c5d-980e-a54b63c1c888', {
    autoload: true,
  });
  Crisp.setSafeMode(true);
  return {
    provide: {
      crisp: Crisp,
    },
  };
});
