<script setup lang="ts">
import * as icons from 'lucide-vue-next';

export type IconName = keyof typeof icons.icons;

const props = defineProps<{
  name: IconName;
  size?: number;
  color?: string;
  strokeWidth?: number;
  defaultClass?: string;
}>();

const icon = computed((): (typeof icons.icons)[IconName] => icons[props.name]);
</script>
<template>
  <component :is="icon" :size="size" :color="color" :stroke-width="strokeWidth ?? 2" :class="defaultClass" />
</template>
