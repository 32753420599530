import type { RouteRecordRaw } from 'vue-router';

export const exploreGlobalRoutes: RouteRecordRaw[] = [
  {
    name: 'explore-global-index',
    component: () => import('../pages/index.vue'),
    path: '/explore/global',
    meta: {
      middleware: 'auth',
    },
  },
];
