import type { RouteRecordRaw } from 'vue-router';

export const exploreRoutes: RouteRecordRaw[] = [
  {
    name: 'explore-index',
    component: () => import('../pages/index.vue'),
    path: '/explore',
    meta: {
      middleware: 'auth',
    },
    children: [
      {
        name: 'explore-chronological',
        component: () => import('../pages/tabs/chronological.vue'),
        path: 'chronological',
        meta: {
          middleware: 'auth',
        },
      },
      {
        name: 'explore-by-zone',
        component: () => import('../pages/tabs/zone.vue'),
        path: 'by-zone',
        meta: {
          middleware: 'auth',
        },
      },
      {
        name: 'explore-comparison',
        component: () => import('../pages/tabs/comparison.vue'),
        path: 'comparison',
        meta: {
          middleware: 'auth',
        },
      },
    ],
  },
];
