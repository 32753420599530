import localforage from 'localforage';

import { meterStore } from './meter';
import type { Client } from '~/types/client';
import type { Site } from '~/types/site';

export const globalStore = defineStore('globalStore', () => {
  // state
  const loading = ref(true);
  const selectedClient = ref<Client['id'] | null>(null);
  const selectedSite = ref<number[]>([]);

  // getters
  const getLoading = computed(() => loading.value);
  const getSelectedClient = computed(() => selectedClient.value);
  const getSelectedSite = computed(() => selectedSite.value);

  // actions
  async function initApp() {
    loading.value = true;
    const firebase = firebaseStore();
    const { $posthog } = useNuxtApp();
    const user = userStore();

    if (firebase.getFirebaseUser) {
      loading.value = true;
      await user.loadUsers();
      // const accessToken = await firebase?.getAccessToken;

      // if (accessToken) {
      //   useGqlHeaders({
      //     Authorization: `Bearer ${accessToken}`,
      //   });
      // }

      // Load clients and set one as default
      await clientStore().loadClients();
      await siteStore().loadSites();

      await Promise.all([
        meterStore().loadMeters(),
        areaStore().loadAreas(),
        consumptionObjectiveStore().loadConsumptionObjectives(),
        meterTypesStore().loadMeterTypes(),
        unitsStore().loadUnits(),
        alertStore().loadAlerts(),
        alertStore().loadAlertAnalyticType(),
        notificationStore().initNotifications(),
        contractStore().loadContracts(),
      ]);

      // Set the current user as the user in posthog
      const currentUser = user.getCurrentUser;
      if (currentUser) {
        $posthog.identify(currentUser.id, {
          email: currentUser.email,
          name: `${currentUser.first_name} ${currentUser.last_name}`,
          client_id: currentUser.client.name,
        });
        $posthog.group('client', currentUser.client.id.toString(), {
          client_id: currentUser.client.id,
          name: currentUser.client.name,
        });
        $posthog.capture('user_updated');
      }
    }
    loading.value = false;
  }

  async function setSelectedClient(clientId: Client['id'] | null) {
    selectedClient.value = clientId;
    if (clientId !== null) {
      await localforage.setItem<number>(`nrjx-selected-client`, clientId);
    } else {
      await localforage.removeItem(`nrjx-selected-client`);
    }
  }

  async function setSelectedSite(siteId: Site['id'] | string) {
    if (typeof siteId === 'string' && siteId === 'all') {
      selectedSite.value = siteStore().getSites.map((site) => site.id);
      await localforage.removeItem(`nrjx-selected-site`);
    } else if (typeof siteId === 'number') {
      selectedSite.value = [siteId];
      await localforage.setItem<number>(`nrjx-selected-site`, siteId);
    }
  }

  function setLoading(isLoading: boolean) {
    loading.value = isLoading;
  }

  return {
    // state
    loading,
    selectedClient,
    selectedSite,
    // getters
    getLoading,
    getSelectedClient,
    getSelectedSite,
    // actions
    initApp,
    setSelectedClient,
    setSelectedSite,
    setLoading,
  };
});
