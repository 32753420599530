import { ApolloClient, HttpLink, InMemoryCache, concat, split } from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

export const useApollo = () => {
  const AUTH_TOKEN = 'apollo-token';
  const { $firebase } = useNuxtApp();
  const config = useRuntimeConfig().public;
  const httpLink = new HttpLink({ uri: config.graphqlUrl });

  const getToken = async (): Promise<string | undefined> => {
    const user: any = $firebase.currentUser;
    const token: string | undefined = await user?.getIdToken();
    if (token) localStorage.setItem(AUTH_TOKEN, token);
    return token;
  };

  const authMiddleware: any = async (operation: any, forward: any) => {
    const token = await getToken();
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : 'token bad',
      },
    });
    return forward(operation);
  };
  const httpLinkWithAuth = concat(authMiddleware, httpLink);

  const wsLink = new GraphQLWsLink(
    createClient({
      url: config.graphqlUrl,
      connectionParams: async () => {
        const token = await getToken();
        return {
          headers: {
            authorization: token ? `Bearer ${token}` : 'token bad',
          },
        };
      },
      shouldRetry: () => true,
    }),
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLinkWithAuth,
  );

  const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  return { apolloClient };
};
