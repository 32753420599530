import type { RouteRecordRaw } from 'vue-router';

export const overviewRoutes: RouteRecordRaw[] = [
  {
    name: 'overview-index',
    component: () => import('../pages/index.vue'),
    path: '/',
    meta: {
      middleware: ['auth'],
    },
  },
];
