import validate from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_detgp5hi6dobrpg6yximild2uu/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/home/runner/work/nrjx/nrjx/apps/webapp/app/middleware/maintenance.global.ts";
import manifest_45route_45rule from "/home/runner/work/nrjx/nrjx/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_detgp5hi6dobrpg6yximild2uu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/nrjx/nrjx/apps/webapp/app/middleware/auth.ts")
}