import { defineStore } from 'pinia';
import { OBJECTIVES_QUERY_LIST } from '~/graphql/objective';

export interface ConsumptionObjective {
  id: number;
  client_id: number;
  name: string;
  definition: {
    type: string;
    objective_rate: number;
  };
  analytic_id: number;
  from: string;
  end: string;
  target_consumption: number;
  sub_objectives: Array<{
    timestamp: string; // IsoString
    target_consumption: number;
  }>;

  analytic?: any;
}

export const consumptionObjectiveStore = defineStore({
  id: 'consumptionObjectiveStore',
  state: () => ({
    consumptionObjectives: {
      list: [] as Array<ConsumptionObjective>,
    },
  }),
  getters: {
    getConsumptionObjectives(): Array<ConsumptionObjective> {
      return this.consumptionObjectives.list.filter((item) => item.client_id === globalStore().getSelectedClient);
    },
  },
  actions: {
    getAnalyticForConsumptionObjective(consumptionObjectiveId: number) {
      const objective = this.consumptionObjectives.list.find((item) => item.id === consumptionObjectiveId);
      if (!objective) return null;

      return objective.analytic;
    },
    async loadConsumptionObjectives(): Promise<void> {
      const { apolloClient } = useApollo();

      const formatAnalytic = (item: any) => {
        const type = ['third_party_by_minute', 'third_party_hourly', 'third_party_hourly_water', 'third_party_hourly_gas'].includes(
          item.meter_type.name,
        )
          ? 'third_party'
          : item.meter_type.name;

        let params;
        if (type === 'enedis') {
          params = { prm: item.data_source_id };
        } else if (type === 'grdf') {
          params = { pce_id: item.data_source_id };
        } else if (type === 'virtual_pressac') {
          params = { uuids: item.data_source_id.split(',') };
        } else {
          params = { uuid: item.data_source_id };
        }

        const name = type === 'enedis' ? 'Electricité' : type === 'grdf' ? 'Gaz' : item.area.name;

        return {
          client_id: item.area.site.client_id,
          id: item.id,
          name,
          params,
          area: item.area,
          site_id: item.area.site.id,
          production_level: item.area.production_reporting_frequency,
          type,
          meter_type: item.meter_type,
        };
      };

      const formatObjective = (item: any) => {
        const sub_objectives = [
          { timestamp: item.start, target_consumption: 0 },
          ...item.objective_milestones.map((item: any) => ({
            timestamp: item.timestamp,
            target_consumption: item.target,
          })),
          { timestamp: item.end, target_consumption: item.target },
        ];

        return {
          id: item.id,
          client_id: item.meter.area.site.client_id,
          name: item.name,
          analytic_id: item.meter.id,
          from: item.start,
          end: item.end,
          target_consumption: item.target,
          sub_objectives,
          analytic: formatAnalytic(item.meter),
        };
      };

      const objectives_list = await apolloClient.query({
        query: OBJECTIVES_QUERY_LIST,
      });

      this.consumptionObjectives.list = objectives_list?.data?.objectives.map((item: any) => formatObjective(item));
    },

    setConsumptionObjectives(consumptionObjectives: Array<ConsumptionObjective>): void {
      this.consumptionObjectives.list = consumptionObjectives;
    },
    async getConsumptionObjective(consumptionObjectiveId: number): Promise<any> {
      return this.consumptionObjectives.list.find((item) => item.id === consumptionObjectiveId);
    },
  },
});
