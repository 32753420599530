import type { RouteRecordRaw } from 'vue-router';

export const maintenanceRoutes: RouteRecordRaw[] = [
  {
    name: 'maintenance-index',
    component: () => import('../pages/index.vue'),
    path: '/maintenance',
    meta: {
      layout: 'auth',
    },
  },
];
